import React from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import Icon from "../Icon";

const Button = (props) => {
  const {
    children,
    className,
    iconLeft,
    iconRight,
    onClick,
    text,
    title,
    type,
    variant,
    width,
    alignText,
    disabled,
  } = props;

  return (
    <div
      className={`${styles.container} ${className} ${styles["align-text-" + alignText]}`}
    >
      <button
        className={variant ? styles[variant] : ""}
        style={{ width: width }}
        type={type}
        onClick={onClick}
        title={title}
        disabled={disabled}
      >
        <>
          {iconLeft ? <Icon icon={iconLeft} /> : null}
          {children || text ? (
            <span className={styles["button-text"]}>
              {children ? children : text}
            </span>
          ) : null}
          {iconRight ? <Icon icon={iconRight} /> : null}
        </>
      </button>
    </div>
  );
};

// @ts-ignore
Button.defaultProps = {
  type: "button",
  variant: "filled",
  width: "100%",
  disabled: false,
  alignText: "center",
  className: "",

};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  variant: PropTypes.oneOf(["filled", "outlined"]),
  width: PropTypes.string,
  alignText: PropTypes.oneOf(["left", "center", "right"]),
  disabled: PropTypes.bool,
}
export default Button;
