import React, { useEffect } from 'react';
import './css/style.scss';
import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import Home from './pages/Home';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, []); // triggered on route change

  return (
    <>
     <Home />
    </>
  );
}

export default App;
