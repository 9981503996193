import React from "react";
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Icon.module.scss";
import Icons from "./Icons";


const Icon = ({ className, icon, size, intent, title, onClick }) => {
  return (
    <div
      className={`${styles.container} ${styles["intent-" + intent]} ${
        className || ""
      }`}
      title={title}
      onClick={onClick}
    >
      <FontAwesomeIcon size={size} icon={Icons[icon]} />
    </div>
  );
};

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(Icons)),
  size: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
}

Icon.defaultProps = {
  size: "1x",
};

export default Icon;
