import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Button from '../Button'
import Icon from '../Icon'
import InputButton from '../InputButton'


import StepWizard from "react-step-wizard";
// import * as hubspot from "@hubspot/api-client"

import { Amplify, API } from 'aws-amplify';
import config from '../../aws-exports.js'
import Progress from './Progress.js'

Amplify.configure(config);
  
const Hexagon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 725 770" className='wizard-hexagon'>
        <polygon points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314"/>
    </svg>
)

const Wizard = (props) => {

    const [activeStep, setActiveStep] = useState(1)

    const [selections, setSelections] = useState({
        eConsent: '',
        deviceData: '',
        notifications: '',
        dashboard: '',
        therapeuticArea: ''
    });
    const [eConsent, setEConsent] = useState(null);
    const [deviceData, setDeviceData] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [therapeuticArea, setTherapeuticArea] = useState(null);
    

    const [contactInfo, setContactInfo] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });
    const [hubspotData, setHubspotData] = useState(null);
    const [hubspotContactExists, setHubspotContactExists] = useState(null);
    const [hubspotContactId, setHubspotContactId] = useState(null);

    useEffect(() => { 
        if (hubspotData != null) {
            if (hubspotData?.length === 0) {
                setHubspotContactExists(false);
            }
            else {
                setHubspotContactExists(true);
                setHubspotContactId(hubspotData[0].id);
            }
        }
        
    }, [hubspotData]); 

        
    function handleEConsent(value) {
        setEConsent(value);
    };

    function handleDeviceData(value) {
        setDeviceData(value);
    };

    function handleNotifications(value) {
        setNotifications(value);
    };

    function handleDashboard(value) {
        setDashboard(value);
    };

    function handleTherapeuticArea(value) {
        setTherapeuticArea(value);
        const add_properties = async () => {
            if (value != null) {
                if(hubspotContactExists) {
                    //call update endpoint
                    var intakeInfo = {
                        body: {
                            consent_preference: eConsent,
                            datasource_preference: deviceData,
                            notification_preference: notifications,
                            dashboard_preference: dashboard,
                            therapeutic_area: value
                        }
                    };
                    const response = await API.post('hubspotAPI','/api/update_user/' + hubspotContactId, intakeInfo);
                }
                else {
                    var intakeInfo = {
                        body: {
                            firstname: contactInfo.firstName,
                            lastname: contactInfo.lastName,
                            email: contactInfo.email,
                            consent_preference: eConsent,
                            datasource_preference: deviceData,
                            notification_preference: notifications,
                            dashboard_preference: dashboard,
                            therapeutic_area: value
                        }
                    };
                    const response = await API.post('hubspotAPI', '/api/create_user', intakeInfo);
                }
            }
        }
        // check if all properties chosen
        // then axios post
        // check if they have hubspot contact
        // if yes, just call update endpoint with properties 
        // if no, then call create endpoint with properties
        add_properties();
    };

    function handleContactInfo(value) {
        setContactInfo(value);
        const get_data = async () => {
            //var email = "suvarnat@careevolution.com"
            //var email = "blamblam@careevolution.com"
            const email_string = encodeURIComponent(value.email)
            const data = await API.get('hubspotAPI','/api/get_user/'+email_string).then((value) => {
                setHubspotData(value);
            });
            //setHubspotData(data);

            //console.log(data);
        
        }
        get_data();
        
    }
    function handleStepChange({ activeStep }) {
        setActiveStep(activeStep)
    }

    return (
        <StepWizard isHashEnabled nav={<Progress />} className={`wizard wizard-step-${activeStep}`} onStepChange={handleStepChange}>
            <ContactInfo stepName="ContactInfo" onChange={handleContactInfo}/>
            <EConsent stepName="EConsent" eConsent={eConsent} onChange={handleEConsent}/>
            <DeviceData stepName="DeviceData" deviceData={deviceData} onChange={handleDeviceData}/>
            <Notifications stepName="Notifications" notifications={notifications} onChange={handleNotifications}/>
            <Dashboard stepName="Dashboard" dashboard={dashboard} onChange={handleDashboard}/>
            <TherapeuticArea stepName="TherapeuticArea" therapeuticArea={therapeuticArea} onChange={handleTherapeuticArea}/>
            <Resources stepName='Resources' dashboard={dashboard} deviceData={deviceData}/>
        </StepWizard>
    );
};
export default Wizard;
        
const ContactInfo = (props) => {
    
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    
    const handleContactInfo = (event) => {
        // Here, we invoke the callback with the new value
        let contactInfo = {
            firstName: firstName,
            lastName: lastName,
            email: email
        };

        props.onChange(contactInfo);
        props.goToNamedStep('EConsent')
    }
    
    return (
        <div className='wizard-step'>
            <div className='wizard-step-wrapper'>
                <section className="wizard-step-content">
                    <header>
                        <h2>Meet Participants <span>Where They Are</span></h2>
                        <p>Find out how MyDataHelps can digitize your project in less than 1 minute.</p>
                    </header>
                    <section className='form-box'>
                        <div className="form-group">
                            <input type="text" id="fname" name="fname" onChange={(event) => setFirstName(event.target.value)} placeholder='First Name'/>
                            <input type="text" id="lname" name="lame" onChange={(event) => setLastName(event.target.value)} placeholder='Last Name'/>
                            <input type="email" id="email" name="email" onChange={(event) => setEmail(event.target.value)} placeholder='Email'/>
                        </div>
                        <Button
                            text="Next"
                            onClick={(value) => handleContactInfo(value)}
                            iconRight={"chevronRight"}
                        />
                    </section>
                </section>
                <aside className="wizard-image image-top">
                    <img src={'/images/wizard-step-1.png'} alt="Illustration of a hand with a beaker" style={{right: "40%", maxWidth: '70%'}}/>
                    <Hexagon />
                </aside>
            </div>
        </div>
    );
}

const EConsent = (props) => {
    const [consentValue, setConsentValue] = useState(null) 

    const handleSelectEConsent = (event) => {
        setConsentValue(event.target.value)
    }

    const handleEConsent = () => {
        // Here, we invoke the callback with the new value
        props.onChange(consentValue); //this is the data we submit


        props.goToNamedStep('DeviceData')
    }
    return (
        <div className='wizard-step-wrapper'>
            <section className="wizard-step-content">
                <header>
                    <h2>Onboarding & Consent</h2>
                    <p>How do you plan to onboard participants to your project?</p>
                </header>
                <section className='form-box'>
                    <div className="button-group">
                        <InputButton 
                            id="consent-MDH"
                            name="consent"
                            value="MDH" 
                            onChange={handleSelectEConsent} 
                            text="MyDataHelps eConsent" 
                            variant="outlined"
                            type="radio"
                            alignText="left" 
                        />
                        <InputButton 
                            id="consent-complex"
                            name="consent"
                            value="complex" 
                            onChange={handleSelectEConsent} 
                            text="MyDataHelps waitlists, pre-screeners, accountless login, etc.)" 
                            variant="outlined"
                            type="radio"
                            alignText="left" 
                        />
                        <InputButton 
                            id="consent-external"
                            name="consent"
                            value="external" 
                            onChange={handleSelectEConsent} 
                            text="External process" 
                            variant="outlined"
                            type="radio"
                            alignText="left" 
                        />
                        <InputButton 
                            id="consent-not-sure"
                            name="consent"
                            value="notSure" 
                            onChange={handleSelectEConsent} 
                            text="I'm not sure" 
                            variant="outlined"
                            type="radio"
                            alignText="left" 
                        />
                    </div>
                    <Button
                        text="Next"
                        onClick={handleEConsent}
                        iconRight={"chevronRight"}
                    />
                </section>
            </section>
            <aside className="wizard-image image-bottom">
                <img 
                    src={'/images/wizard-step-2.png'} 
                    alt="Illustration of a hand signing a document" 
                    style={{maxWidth: '73%', right: '32%'}}
                />
                <Hexagon />
            </aside>
        </div>
    );
}

const DeviceData = (props) => {
    const [data, setData] = useState({
        devices: []
    });
      
    const fitbitRef = useRef(null)
    const appleHealthRef = useRef(null)
    const googleFitRef = useRef(null)
    const ehrRef = useRef(null)
    const noneRef = useRef(null)
    const iDontKnowRef = useRef(null)

    const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { devices } = data;
        
        
        if (checked && value === 'None') {
            setData({
                devices: [value]
            });
            fitbitRef.current.checked = false
            appleHealthRef.current.checked = false
            googleFitRef.current.checked = false
            ehrRef.current.checked = false
            iDontKnowRef.current.checked = false
        } else if (checked && value === "I don't know") {
            setData({
                devices: [value]
            });
            fitbitRef.current.checked = false
            appleHealthRef.current.checked = false
            googleFitRef.current.checked = false
            ehrRef.current.checked = false
            noneRef.current.checked = false

        } else if (checked && !['None', "I don't know"].includes(value)) {
            // Remove "None" / "I don't know" from the selected options
            const filteredDevices = devices.filter((v) => (!['None', "I don't know"].includes(v)))
            setData({
                devices: [...filteredDevices, value]
            });
            noneRef.current.checked = false
            iDontKnowRef.current.checked = false
        } else {
            setData({
                devices: devices.filter((e) => e !== value)
            });
        }
    };
    
    const handleDeviceData = () => {
        // Here, we invoke the callback with the new value
        //iterate through the list of strings in devices and concat
        let finalDeviceString = "";
        for (let device in data.devices) {
            finalDeviceString += data.devices[device];
            if (device != data.devices.length - 1) {
                finalDeviceString += ",";
            }
        }
        props.onChange(finalDeviceString); //this is the data we submit
        props.goToNamedStep('Notifications')
    }

    return (
        <div className='wizard-step-wrapper'>
            <section className="wizard-step-content">
                <header>
                    <h2>Wearable & EHR Data</h2>
                    <p>Would you like to use digital biomarkers and collect novel data points to enhance your analysis?</p>
                </header>
                <section className='form-box'>
                    <div className="button-group">
                        <InputButton 
                            variant="outlined" 
                            type="checkbox" 
                            id="wearables-fitbit" 
                            name="wearables" 
                            value="Fitbit" 
                            text="Fitbit" 
                            onChange={handleChange}
                            ref={fitbitRef}
                        />
                        <InputButton 
                            variant="outlined" 
                            type="checkbox" 
                            id="wearables-apple-health" 
                            name="wearables" 
                            value="Apple Health" 
                            text="Apple Health" 
                            onChange={handleChange}
                            ref={appleHealthRef}
                        />
                        <InputButton 
                            variant="outlined" 
                            type="checkbox" 
                            id="wearables-google-fit" 
                            name="wearables" 
                            value="Google Fit" 
                            text="Google Fit" 
                            onChange={handleChange}
                            ref={googleFitRef}
                        />
                        <InputButton 
                            variant="outlined" 
                            type="checkbox" 
                            id="wearables-ehr" 
                            name="wearables" 
                            value="EHR" 
                            text="Electronic Health Records (EHR)" 
                            onChange={handleChange}
                            ref={ehrRef}
                        />
                        <InputButton 
                            variant="outlined" 
                            type="checkbox" 
                            id="wearables-none" 
                            name="wearables" 
                            value="None" 
                            text="None" 
                            onChange={handleChange}
                            ref={noneRef}
                        />
                        <InputButton 
                            variant="outlined" 
                            type="checkbox" 
                            id="wearables-i-don-t-know" 
                            name="wearables" 
                            value="I don't know" 
                            text="I don't know" 
                            onChange={handleChange}
                            ref={iDontKnowRef}
                        />
                    </div>                                                                                                                                     
                    <Button
                        text="Next"
                        onClick={(value) => handleDeviceData()}
                        iconRight={"chevronRight"}
                    />
                </section>
            </section>
            <aside className="wizard-image image-bottom">
                <img src={'/images/wizard-step-3.png'} alt="Illustration of hand showcasing a smart watch or fitness device" />
                <Hexagon />
            </aside>
        </div>
    );
}

const Notifications = (props) => {
    const [data, setData] = useState({
        notifications: null
      });
      
      const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { notifications } = data;

        setData({
                notifications: value
            });
      };
    
    const handleNotifications = () => {
        // Here, we invoke the callback with the new value
        props.onChange(data.notifications);
        props.goToNamedStep('Dashboard')
    }

    return (
        <div className='wizard-step-wrapper'>
            <section className="wizard-step-content">
                <header>
                    <h2>Notifications</h2>
                    <p>How do you plan to use notifications in your project?</p>
                    </header>
                <section className='form-box'>
                    <div className='button-group'>
                        <InputButton  
                            variant="outlined"  
                            type="radio"  
                            id="notifications-adherence"  
                            name="notifications"  
                            value="Adherence"  
                            onChange={handleChange}  
                            text="Increase protocol adherence"
                        />
                        <InputButton  
                            variant="outlined"  
                            type="radio"  
                            id="notifications-intervention"  
                            name="notifications"  
                            value="Intervention"  
                            onChange={handleChange}  
                            text="Adaptively as an intervention"
                        />                        
                        <InputButton  
                            variant="outlined"  
                            type="radio"  
                            id="notifications-resources"  
                            name="notifications"  
                            value="Resources"  
                            onChange={handleChange}  
                            text="Share resources with users"
                        />
                        <InputButton  
                            variant="outlined"  
                            type="radio"  
                            id="notifications-none"  
                            name="notifications"  
                            value="None"  
                            onChange={handleChange}  
                            text="None"
                        />
                        <InputButton  
                            variant="outlined"  
                            type="radio"  
                            id="notifications-i-dont-know"  
                            name="notifications"  
                            value="I don't know"  
                            onChange={handleChange}  
                            text="I don't know"
                        />
                    </div>                    
                    <Button
                        text="Next"
                        onClick={(value) => handleNotifications()}
                        iconRight={"chevronRight"}
                    />
                </section>
            </section>
            <aside className="wizard-image image-bottom">
                <img src={'/images/wizard-step-4.png'} alt="Illustration of a hand holding a megaphone" />
                <Hexagon />
            </aside>
        </div>
    );
}

const Dashboard = (props) => {
    const [data, setData] = useState({
        dashboard: null,
      });
      
      const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { dashboard } = data;
          
         
        if (checked) {
          setData({
            dashboard: value
          });
        }
      };
    
    const handleDashboard = () => {
        // Here, we invoke the callback with the new value

        props.onChange(data.dashboard);//this is the data we submit
        props.goToNamedStep('TherapeuticArea')
    }
    return (
        <div className='wizard-step-wrapper'>
            <section className="wizard-step-content">
                <header>
                    <h2>Dashboards</h2>
                    <p>How do you plan to construct a unique project interface for your users? </p>
                </header>
                <section className='form-box'>
                    <div className="button-group">
                        <InputButton variant="outlined" type="radio" id="dashboard-custom" name="dashboard" value="Custom" onChange={handleChange} text="MyDataHelps Custom Views self-service dynamic visualizations" />
                        <InputButton variant="outlined" type="radio" id="dashboard-no-dev" name="dashboard" value="NoDev" onChange={handleChange} text="MyDataHelps Custom Views development services"/>
                        <InputButton variant="outlined" type="radio" id="dashboard-standard" name="dashboard" value="Standard" onChange={handleChange} text="MyDataHelps Standard Dashboards"/>
                        <InputButton variant="outlined" type="radio" id="dashboard-external-integration" name="dashboard" value="ExternalIntegration" onChange={handleChange} text="MyDataHelps Embeddables into external applications"/>
                        <InputButton variant="outlined" type="radio" id="dashboard-i-don-t-know" name="dashboard" value="I don't know" onChange={handleChange} text="I'm not sure"/>
                    </div>
                    <Button
                        text="Next"
                        onClick={(value) => handleDashboard()}
                        iconRight={"chevronRight"}
                    />                                                                                                                      
                </section>
            </section>
            <aside className="wizard-image image-center">
                <img src={'/images/wizard-step-5.png'} alt="Illustration of a dashboard and graphs" />
                <Hexagon />
            </aside>
        </div>
    );
}

const TherapeuticArea = (props) => {

    const list = [
        {
            "name": "Cardiology",
            "label": "Cardiology"
        },
        {
            "name": "Mental Health/Psychology",
            "label": "Mental Health/​Psychology",
        },
        {
            "name": "Women's Health",
            "label": "Women's Health"
        },
        {
            "name": "Gastroenterology",
            "label": "Gastroenterology"
        },
        {
            "name": "Immunology",
            "label": "Immunology"
        },
        {
            "name": "Infectious Diseases",
            "label": "Infectious Diseases"
        },
        {
            "name": "Metabolism/Endocrinology",
            "label": "Metabolism/​Endocrinology"
        },
        {
            "name": "Neurology",
            "label": "Neurology"
        },
        {
            "name": "Oncology",
            "label": "Oncology"
        },
        {
            "name": "Orththopedics",
            "label": "Orththopedics"
        },
        {
            "name": "Respirology",
            "label": "Respirology"
        },
        {
            "name": "Substance Abuse",
            "label": "Substance Abuse"
        },
        {
            "name": "Urology",
            "label": "Urology"
        },
        {
            "name": "Pharmacology",
            "label": "Pharmacology"
        }
    ]

    var checkboxes_list = []

    const [data, setData] = useState({
        therapeuticArea: []
      });
      
      const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { therapeuticArea } = data;
          
         
        if (checked) {
          setData({
            therapeuticArea: [...therapeuticArea, value]
          });
        }
        else {
            setData({
                therapeuticArea: therapeuticArea.filter((e) => e !== value)
                });
            }
      };
    
    const handleTherapeuticArea = () => {
        // Here, we invoke the callback with the new value
        let therapeuticString = "";
        for (let therapy in data.therapeuticArea) {
            therapeuticString += data.therapeuticArea[therapy];
            if (therapy != data.therapeuticArea.length - 1) {
                therapeuticString += ",";
            }
        }
        props.onChange(therapeuticString);
        props.goToNamedStep('Resources')
    }

    return (
        <div className='wizard-step-wrapper'>
            <section className="wizard-step-content">
                <header>
                    <h2>Field of Study</h2>
                    <p>What are your research interests?</p>
                </header>
                <div className='form-box'>
                    <div className="button-group">
                        {
                            list.map((item) => (
                                <InputButton 
                                    key={item.name} 
                                    variant="outlined" 
                                    type="checkbox" 
                                    id={`field-of-study-${item.name}`} 
                                    name='field-of-study'
                                    value={item.name} 
                                    onChange={handleChange} 
                                    text={item.label}
                                />
                            ))
                        }
                    </div>
                    <Button
                        text="Submit"
                        onClick={(value) => handleTherapeuticArea()}
                        iconRight={"chevronRight"}
                    />
                </div>
            </section>
            <aside className="wizard-image image-center">
                <img src={'/images/wizard-step-6.png'} alt="Illustration of various medcal implements" />
                <Hexagon />
            </aside>
        </div>
    );
}
const ResultCard = (props) => {
    return (
        <Card sx={{ minWidth: 150, height: "100%" }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {props.specialty}
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                    {props.title}
                                    </Typography>
                                    <Typography variant="body2">
                                    {props.text}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small"><a style={{textDecoration: "none", color: "white"}} href={props.url} target={"_blank"}>Learn More </a></Button>
                                </CardActions>
                            </Card>
    )
}
const Resources = (props) => {
    const [card1, setCard1] = useState({
        specialty: "Standard Dashboards",
        title: "Framingham Heart Study",
        text: "30% higher completion rates than previous eFHS studies",
        url: "https://careevolution.com/case-studies/framingham-heart-study/"
    });
    const [card2, setCard2] = useState({
        specialty: "Apple Health Integrations",
        title: "PROMPT",
        text: "This University of Michigan mental health study had 82.6% completion rates",
        url: "https://careevolution.com/case-studies/university-of-michigan-prompt-study/"
    });
    const [card3, setCard3] = useState({
        specialty: "High adherence",
        title: "MIPACT",
        text: "Apple Health and Fitbit Integrations, with a 98.3% adherence rate",
        url: "https://careevolution.com/case-studies/michigan-predictive-activity-and-clinical-trajectories-mipact/"
    });
    const [card4, setCard4] = useState({
        specialty: "Large scale EHR + Google Fit",
        title: "All of Us",
        text: "104k+ participants with a focus on diversity and rich biological data",
        url: "https://careevolution.com/case-studies/facilitating-precision-medicine-research-through-the-all-of-us-research-program/"
    });
    useEffect(() => { 
        // this will be conditional choice logic
        if(props.dashboard != null && props.deviceData != null) {
            if(props.dashboard.includes("Custom")) {
                setCard1({
                    specialty: "Custom Dashboards Done Fast",
                    title: "ITAP - UMass Medical",
                    text: "Less than 2 weeks of lead time for a study with custom dashboards",
                    url: "https://careevolution.com/case-studies/independent-testing-assessment-program-itap-digital-clinical-studies-protocol-approval-to-study-launch-in-only-2-weeks/"
                })
            }
            // if (props.deviceData.includes("Apple Health") && props.deviceData.includes("Google Fit")) {
            //     console.log('both google and apple health');


            // } else if (props.deviceData.includes("Google Fit")) {
            //     console.log('just google');
            //     setCard2({
            //         specialty: "Google Fit Integrations",
            //         title: "PROGRESS",
            //         text: "Google Fit and EHR integrations for a glycemic response study",
            //         url: "https://careevolution.com/case-studies/the-scripps-research-digital-trials-centers-prediction-of-glycemic-response-study-progress/"
            //     })
            // } 
        }
        
        
    }, [props]); 

    return (
        <div className='wizard-step-wrapper'>
            <section className="wizard-step-content">
                <header>
                    <h2>All Done</h2> 
                    <p>Thank you for showing interest in MyDataHelps!</p>
                </header>
                <section className='form-box'>
                    <p>Check out these resources for more information about MyDataHelps!</p>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                            <ResultCard 
                            specialty={card1.specialty}
                            title={card1.title}
                            text={card1.text}
                            url={card1.url}
                            />  
                            </Grid>
                            <Grid item xs={6}>
                            <ResultCard 
                            specialty={card2.specialty}
                            title={card2.title}
                            text={card2.text}
                            url={card2.url}
                            />  
                            </Grid>
                            <Grid item xs={6}>
                            <ResultCard 
                            specialty={card3.specialty}
                            title={card3.title}
                            text={card3.text}
                            url={card3.url}
                            />  
                            </Grid>
                            <Grid item xs={6}>
                            <ResultCard 
                            specialty={card4.specialty}
                            title={card4.title}
                            text={card4.text}
                            url={card4.url}
                            />                            
                            </Grid>
                        </Grid>
                    </Box>
                </section>
            </section>
            <aside className="wizard-image image-bottom">
                <img src={'/images/wizard-step-7.png'} alt="Illustration of hands raised in praise" style={{ maxWidth: '70%', right: '48%'}}/>
                <Hexagon />
            </aside>
        </div>
    );
}