import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  // const { t } = useTranslation();
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    <header className='site-header'>
      <img src={'/images/mydatahelps-logo.svg'} alt="MyDataHelps" />
    </header>
  );
}

export default Header;
