import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUp,
  faBars,
  faBook,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleXmark,
  faCode,
  faCopy,
  faCreditCard,
  faGear,
  faHome,
  faInfoCircle,
  faKey,
  faLink,
  faPaperPlane,
  faPlus,
  faQuestionCircle,
  faSpinner,
  faTachometerAlt,
  faTerminal,
  faClose,
  faTrashCan,
  faUserCog,
  faUserGear,
  faWarning,
  faXmark,
  faRepeat,
  faUpload,
  faSquare,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Setting an implicit list of available icons to optimize our import of SVGs
 * from the FA icon library. Also gives us a way to namespace and keep the interface
 * with a 3rd party library isolated and abstracted.
 */
const Icons = {
  arrowDown: faArrowDown,
  arrowLeft: faArrowLeft,
  arrowRight: faArrowRight,
  arrowRightFromBracket: faArrowRightFromBracket,
  arrowUp: faArrowUp,
  bars: faBars,
  book: faBook,
  caretDown: faCaretDown,
  caretLeft: faCaretLeft,
  caretRight: faCaretRight,
  caretUp: faCaretUp,
  check: faCheck,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  circleX: faCircleXmark,
  close: faClose,
  code: faCode,
  copy: faCopy,
  creditCard: faCreditCard,
  gear: faGear,
  help: faQuestionCircle,
  home: faHome,
  info: faInfoCircle,
  key: faKey,
  link: faLink,
  paperPlane: faPaperPlane,
  plus: faPlus,
  spinner: faSpinner,
  tachometerAlt: faTachometerAlt,
  terminal: faTerminal,
  trashCan: faTrashCan,
  upload: faUpload,
  userCog: faUserCog,
  userGear: faUserGear,
  warning: faWarning,
  xmark: faXmark,
  repeat: faRepeat,
  square: faSquare,
  squareCheck: faSquareCheck,
};

export default Icons;
