import React, { useEffect} from 'react';
import Header from '../partials/Header';
import Wizard from '../partials/wizard/wizard';

const docTitle = "Project Onboarding Wizard"
// const tagManagerArgs = {
//   dataLayer: {
//       event: 'page_view',
//       page_location: document.location.href,
//       page_title: docTitle
//   }
// }

function Home() {
  // TagManager.dataLayer(tagManagerArgs)

  useEffect(() => {
    document.title = docTitle; 
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}

        <main className="flex-grow">
        <section className="dark-bg wizard-wrap">           
          {/* className='text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4'  */}
          {/* <WizardDemo/> */}

              <Wizard />
        </section>

  

        {/* <HeroHome /> */}
          {/* <Testimonials /> */}
          {/* <Newsletter /> */}
        </main>


      {/*  Site footer */}
      {/* <Footer /> */}

    </div>
  );
}

export default Home;