import React from 'react';
import PropTypes from 'prop-types'

const Progress = ({totalSteps, currentStep, goToStep}) => {
  const steps = []
  steps.length = totalSteps
  steps.fill(null)
  return (
    <div className='progress'>
      {
        steps.map((i, index) => (
          <button 
            key={index + 1} 
            className={`progress-step ${index + 1 <= currentStep ? 'active' : ''}`} 
            onClick={() => goToStep(index + 1)}
            title={`Step ${index + 1}`}
          />
        ))
      }
    </div>
  )
}

Progress.defaultProps = {
  totalSteps: 6, 
  currentStep: 0,
}

Progress.propTypes = {
  totalSteps: PropTypes.number, 
  currentStep: PropTypes.number,
  goToStep: PropTypes.func,
}

export default Progress