import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import styles from "./InputButton.module.scss";
import Icon from "../Icon";

const InputButton = forwardRef((props, ref) => {
  const {
    children,
    className,
    onChange,
    text,
    title,
    type,
    iconLeft,
    iconRight,
    variant,
    alignText,
    disabled,
    id,
    name,
    value,
  } = props;

  const isChecked = ref && ref.current && ref.current.checked

  return (
    <div
      className={`${styles.container} ${className} ${styles["align-text-" + alignText]} ${variant ? styles[variant] : ''} ${disabled ? 'disabled': ''} ${isChecked ? 'checked' : ''}`}
    >
      <input
        type={type}
        title={title}
        disabled={disabled}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        ref={ref}
      />
      <label htmlFor={id}>
        {iconLeft ? <Icon icon={iconLeft} /> : null}
        {children || text || name ? (
          <span className={styles["input-button-text"]}>
            {children ? children : text || name}
          </span>
        ) : null}
        {iconRight ? <Icon icon={iconRight} /> : null}
      </label>
      {
        type === 'checkbox' ? (
          <Icon 
            icon={isChecked ? 'squareCheck' : 'square'} 
            className={styles['checkbox']}
          />
        ) : ''
      }
    </div>
  );
});

// @ts-ignore
InputButton.defaultProps = {
  type: "button",
  variant: "filled",
  disabled: false,
  alignText: "left",
  className: "",
  name: "",
  value: "",
};

InputButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onChange: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["button", "checkbox", "radio"]),
  variant: PropTypes.oneOf(["filled", "outlined"]),
  alignText: PropTypes.oneOf(["left", "center", "right"]),
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
}
export default InputButton;
